.totalElementCount {
  color: var(--BMediumGray);
  font-weight: 400;
  margin-bottom: 10px;
}

.inside {
  color: var(--BDarkGray);
  margin-bottom: auto;
}
