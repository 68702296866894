.btnmobile {
  display: flex;
  justify-content: end;
}

.btnMobileNav {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #333;
  display: none;
}

.iconMobileNav {
  width: 32px;
  height: 32px;
}

.iconMobileNav[name="close"] {
  display: none;
}

.AutocompleteSearchbox {
  z-index: 1000;
  background-color: #fff;
}

.AutocompleteSearchbox:focus {
  outline: none;
}

.AutocompleteSearchbox fieldset {
  border: 1px solid var(--BLightGray);
}

@media (max-width: 48em) {
  /* .btnMobileNav {
    display: block;
    padding-left: 12px;
  }
  .icon {
    border-radius: 5px;
  }
  .searchbox {
    background-color: rgb(255, 255, 255, 0.97);
    position: absolute;
    top: 60px;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .showsearchbox {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  } */

  .AutocompleteSearchbox {
    /* border: 2px solid #377faa; */
    inset: 1px;
    /* border-radius: 10px; */
  }
}
