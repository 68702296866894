.votingGrid {
  font-size: 1rem;
}

.votingScore {
  font-size: 1rem;
  font-weight: 600;
  color: var(--BNavy);
  text-align: center;
}
