.termCardListSubHeader {
  font-size: 1.5rem;
  color: var(--BDarkGray);
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.illustration {
  position: sticky;
  /* left: 75%; */
  /* transform: translate(-50%, 0); */
  top: 25vh !important;
}

.illustration img {
  max-width: 100%;
  max-height: 70vh;
}

@media screen and (max-width: 1200px) {
  /* .illustration {
    padding-left: 20px;
    padding-right: 20px;
  } */
  .illustration img {
    max-width: 400px;
  }
}
