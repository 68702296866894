.notification {
  margin-bottom: 12px;
  -webkit-border-radius: 10px;
  /* Safari, Chrome */
  -moz-border-radius: 10px;
  /* Firefox */
  border-radius: 6px;
  box-shadow: var(--ShadowDark);
  border: none;
  padding: 12px;
}

.notificationDate {
  font-size: 12px;
  margin-top: 5px;
}
