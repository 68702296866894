/* .rightBorder {
  padding-right: 10px;
  border-right: solid;
  border-right-color: var(--BMediumGray);
} */

.menu {
  padding: 0px !important;
  margin-bottom: 0px;
}

.menu ul {
  padding-left: 1px !important;
}

.menu li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li a {
  display: block;
  text-align: left;
}

.menu li a .selectedClass {
  background-color: #730d0d;
  color: green;
}

@media (max-width: 48rem) {
  .menu li {
    border-bottom: 1px solid var(--BLightGray);
  }
}
