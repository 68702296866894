.bottomMargin {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.deletePage {
  padding: 20px 19px;
  background-color: var(--BWhite);
  min-height: 100%;
  box-shadow: var(--ShadowDark);
  border-radius: 6px;
}

.newtermTop {
  margin-bottom: 37px;
  border: none;
  box-shadow: var(--ShadowDark);
  padding: 10px;
  background-color: white !important;
  /* background-color: transparent; */
  padding-left: 15px;
}

.marginBottom {
  margin-bottom: 37px;
}

@media screen and (max-width: 48rem) {
  .deletePage {
    padding: 20px 20px;
    min-height: 65vh;
  }

  .buttonTopMargin {
    max-width: max-content;
  }
}
