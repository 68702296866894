.pageTitle {
  font-size: 1.5rem;
  color: var(--BNavy);
  margin-bottom: 0px;
  font-weight: 700;
}

.insidePage {
  font-size: 18px;
  line-height: 22px;
  color: var(--BDarkGray);
  font-weight: 700;
}

.titleMarginBottom {
  margin-bottom: 15px;
  color: var(--BDarkGray);
}

.avatar {
  min-height: 40px;
  min-width: 40px;
  margin-top: auto;
  margin-bottom: auto;
  background: var(--BNavy);
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: var(--BWhite);
}

@media screen and (max-width: 48rem) {
  .pageTitle {
    font-size: 1.5rem;
  }
  .insidePage {
    font-size: 18px;
    line-height: 22px;
    color: var(--BDarkGray);
    font-weight: 700;
  }
}
