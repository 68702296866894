.btn {
  align-self: center;
  outline: none;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: bold;
  -webkit-border-radius: 50px;
  /* Safari, Chrome */
  -moz-border-radius: 50px;
  /* Firefox */
  border-radius: 50px;
}

.btn svg {
  margin-top: -2px;
}
.btn:hover {
  transition: all 150ms ease-out;
}

.fullWidth {
  width: 100%;
}

.btn-underline-blue {
  color: var(--BBlue);
}

.btn-text-gray {
  background-color: transparent;
  color: var(--BLightGray);
  align-self: center;
  outline: none;
  border: none;
  font-size: 0.875rem;
  font-weight: bold;
}

.btn-text-gray:hover {
  color: var(--BWhite);
  transition: all 150ms ease-out;
}

.btn-blue {
  background-color: var(--BBlue);
  border-color: var(--BBlue);
  color: white;
}
.btn-blue:hover {
  background-color: var(--BBlue);
  border-color: var(--BBlueHover);
  color: white;
}

.btn-navy {
  background-color: var(--BNavy);
  border-color: var(--BNavy);
  color: white;
}

.btn-navy:hover {
  background-color: var(--BNavyHover);
  border-color: var(--BNavyHover);
  color: white;
}

.btn-green {
  background-color: var(--BGreen);
  border-color: var(--BGreen);
  color: white;
}
.btn-green:hover {
  background-color: var(--BGreenHover);
  border-color: var(--BGreenHover);
  color: white;
}

.btn-red {
  background-color: var(--BRed);
  border-color: var(--BRed);
  color: white;
}
.btn-red:hover {
  background-color: var(--BRedHover);
  border-color: var(--BRedHover);
  color: white;
}

.btn-sign {
  border: 2px solid;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: var(--BNavy);
  border-color: var(--BNavy);
  color: white;
  -webkit-border-radius: 50px;
  /* Safari, Chrome */
  -moz-border-radius: 50px;
  /* Firefox */
  border-radius: 50px;
  padding: 15px 15px;
  font-size: 1rem;
  min-height: 58px;
}
.btn-sign:hover {
  border: 2px solid;
  background-color: var(--BNavyHover);
  border-color: var(--BNavyHover);
  color: white;
  -webkit-border-radius: 50px;
  /* Safari, Chrome */
  -moz-border-radius: 50px;
  /* Firefox */
  border-radius: 50px;
  padding: 15px 15px;
  font-size: 1rem;
}

.btn-gray {
  background-color: var(--BMediumGray);
  border-color: var(--BMediumGray);
  color: white;
}
.btn-gray:hover {
  background-color: var(--BMediumGrayHover);
  border-color: var(--BMediumGrayHover);
  color: white;
}

.btn-yellow {
  background-color: var(--BYellow);
  border-color: var(--BYellow);
  color: var(--BNavy);
}
.btn-yellow:hover {
  background-color: var(--BYellowHover);
  border-color: var(--BYellowHover);
  color: var(--BNavy);
}

.btn-voting {
  align-self: center;
  outline: none;
  font-size: 1.5rem;
  background-color: white;
  border: none;
}
.btn-voting:disabled {
  border-color: var(--BLightGray);
  color: var(--BLightGray);
  opacity: 0.2;
}

.btn-voting:hover:disabled svg {
  background-color: white !important;
}

.btn-voting:hover svg {
  background-color: var(--BBlueLight) !important;
}

.btn-voting-clicked {
  align-self: center;
  outline: none;
  font-size: 1.5rem;
  background-color: white;
  border: none;
}
.btn-voting-clicked:hover svg {
  background-color: var(--BNavyHover) !important;
  border-color: var(--BNavyHover);
}

@media (max-width: 48em) {
  .btn-sign {
    padding: 7px 15px;
    min-height: 30px;
  }
  .btn-sign:hover {
    padding: 10px 15px;
  }
  .btn {
    padding: 10px 20px;
    width: 100%;
  }

  .btn-blue {
    width: 100%;
  }

  .btn-yellow {
    width: 100%;
  }
}

.btn-icon {
  background-color: transparent;
  border: none;
  color: var(--BMediumGray);
  font-size: 1rem;
}
.btn-icon:hover {
  color: var(--BNavyHover);
  transition: all 150ms ease-out;
}

/** Deprecated **/
.btn-underline {
  background-color: transparent;
  color: var(--BMediumGray);
  align-self: center;
  outline: none;
  border: none;
  border-bottom: 3px solid;
  border-color: transparent;
  padding-bottom: 5px;
  font-size: 0.875rem;
  font-weight: bold;
}

.btn-underline:hover {
  color: var(--BNavy);
  border-color: var(--BNavy);
  transition: all 150ms ease-out;
  border-bottom: 3px solid;
}
