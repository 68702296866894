.footer {
  text-align: center;
  padding: 10px;
  background-color: var(--BDarkGray);
  position: -webkit-sticky; /* Safari */
  position: sticky !important;
  top: 100vh !important;
  /* z-index: -1; */
}

.container {
  width: 480px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

@media screen and (max-width: 1220px) {
  .container {
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (max-width: 48em) {
  .footer {
    min-height: 145px;
  }
}
