.notifications {
  position: relative;
}

.alertBtn {
  margin-top: 0.5px;
  transition: 0.5s;
  cursor: pointer;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.list {
  min-height: 250px;
}

.box {
  border-radius: 10px;
  transition: 0.5s;
  position: absolute;
  padding: 20px;
  right: 0;
  margin-top: 10px;
  background-color: #f4f4f4;
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  cursor: context-menu;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  overflow-y: scroll;
  max-height: 250px;
  max-height: 250px !important;
}

.number {
  height: 16px;
  width: 16px;
  background-color: var(--BYellow);
  border-radius: 20px;
  color: var(--BNavy);
  border-color: var(--BWhite);
  text-align: center;
  position: absolute;
  top: 2px;
  font-size: 0.625rem;
  left: 16px;
  border-style: solid;
  border-width: 1px;
}

@media (max-width: 48em) {
  .box {
    border-radius: 0;
    transition: 0.5s;
    position: absolute;
    padding: 10px;
    margin-top: 15px;
    background-color: #f4f4f4;
    -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    cursor: context-menu;
    opacity: 1;
    right: 0;
    max-height: 500px;
  }

  .number {
    top: -2px;
    left: 10px;
  }
}

.showNotifications {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

/* .alertBtn:hover + div {
  display: block;
} */

.number:empty {
  display: none;
}

.notification {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.notification:last-child {
  margin-bottom: 0;
}

.list {
  max-height: 250px;
  overflow: scroll;
}
