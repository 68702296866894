.menuWrapper {
  display: flex;
}

.laOutletContainer {
  flex-grow: 1;
  padding-left: 20px;
  /* padding-top: 2px; */
}
.menuTitle {
  font-size: 18px;
  line-height: 22px;
  color: var(--BDarkGray);
  font-weight: 700;
  margin-bottom: 16px;
}

@media (min-width: 48rem) {
  .menuItems {
    background-color: var(--BWhite);
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: var(--ShadowDark);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 86px;
    border-radius: 6px;
    padding-top: 10px;
    min-width: 233px;
    padding-left: 19px;
    padding-top: 20px;
  }
}

@media (max-width: 48rem) {
  /* .menuWrapper {
		flex-direction: column;
	} */

  .noDisplay {
    display: none !important;
  }

  .laOutletContainer {
    flex-grow: 1;
    padding: 0px;
    min-height: max-content;
    /* z-index: 1; */
    max-width: calc(100vw - 33.6px);
    margin: auto;
    /* background-color: white; */
  }

  .menuItems {
    /* display: flex; */
    /* flex-basis: 100%; */
    background-color: var(--BWhite);
    min-width: calc(100vw - 33.6px);
    overflow: scroll;
    /* max-height: 40px; */
    min-height: 65vh;
    width: max-content;
    /* background-color: var(--BExtraLightGray); */
    border-bottom: 2px solid var(--BLightGray);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    box-shadow: var(--ShadowDark);
    border-radius: 6px;
    padding-top: 20px;
  }
  .menuItems::-webkit-scrollbar {
    display: none;
  }
  .menuItems ul {
    padding-left: 5px;
    /* margin-right: 5px; */
  }
  .menuTitle {
    font-size: 18px;
    padding-left: 16px;
  }
}
