.buttonTopMargin {
  margin-top: 15px;
}
.profilePage {
  padding: 20px 19px;
  background-color: var(--BWhite);
  min-height: 100%;
  box-shadow: var(--ShadowDark);
  border-radius: 6px;
}
.userSpan {
  color: var(--BMediumGray);
}

.textareaAbout {
  padding: 10px;
  border-color: var(--BLightGray);
  width: 100%;
  height: 150px;
  border-radius: 4px;
  font-size: 14px;
}
.textareaAbout:hover {
  border-color: var(--BBlue);
}
.textareaAbout:focus {
  outline: none !important;
  border: 2px solid var(--BBlue);
  /*box-shadow: 0 0 10px var(--BBlue);*/
}

@media screen and (max-width: 48rem) {
  .profilePage {
    padding: 20px 20px;
    min-height: 65vh;
  }
  .buttonTopMargin {
    max-width: max-content;
  }
}
