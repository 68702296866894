.menu {
  font-size: 0.875rem !important;
  font-family: inherit !important;
}

.menuItem {
  font-size: 0.875rem !important;
  color: var(--BDarkGray) !important;
}

.menuItem:hover {
  background: var(--BBlueLight) !important;
  color: var(--BNavy) !important;
}

.menuItemAdmin {
  font-size: 0.875rem !important;
  color: var(--BRed) !important;
}

.menuItemAdmin:hover {
  background: var(--BBlueLight) !important;
}
