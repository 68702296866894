.element {
  margin-bottom: 12px;
  -webkit-border-radius: 6px;
  /* Safari, Chrome */
  -moz-border-radius: 6px;
  /* Firefox */
  border-radius: 6px;
  box-shadow: var(--ShadowDark);
  border: none;
  background-color: var(--BWhite);
  padding: 10px 14px;
}

.elementDate {
  font-size: 14px;
  margin-top: 2px;
  color: var(--BMediumGray);
  font-weight: 500 !important;
}

.elementTitle {
  display: flex;
  justify-content: space-between;
}

.insideDefinition {
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: -29px;
  margin-right: -29px;
}

.pendingTermTitle {
  color: var(--BNavy);
  background: white;
  /*white-space: nowrap;*/
  text-align: center;
  text-decoration: none;
  font-weight: 700;
}
@media screen and (max-width: 48rem) {
  .element {
    padding: 10px 4px;
  }
  .insideDefinition {
    margin-left: -20px;
    margin-right: -20px;
  }
}
