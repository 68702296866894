.buttonTopMargin {
  margin-top: 15px;
  width: 100%;
}

.passwordPage {
  padding: 20px 19px;
  background-color: var(--BWhite);
  min-height: 100%;
  box-shadow: var(--ShadowDark);
  border-radius: 6px;
}
.userSpan {
  color: var(--BMediumGray);
}
@media screen and (max-width: 48rem) {
  .passwordPage {
    padding: 20px 20px;
    min-height: 65vh;
  }
  .buttonTopMargin {
    max-width: max-content;
  }
}
