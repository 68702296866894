.mainContainer {
  /* margin: 0 auto; */
  /* max-width: 1220px; */
  /* padding: 10px; */
  margin-top: 28px;
}

.layout {
  min-height: 100vh;
}

@media screen and (max-width: 48rem) {
  .mainContainer {
    margin-top: 18px;
  }
}
