.emptyAbout {
  color: var(--BMediumGray);
  font-style: italic;
}

.grayed {
  color: var(--BMediumGray);
}

.smallTitle {
  color: var(--BDarkGray);
  font-size: 1.125rem;
  font-weight: 700;
}

.aboutContent {
  flex: 1;
  font-weight: 300;
  color: var(--BDarkGray);
}

.marginBottomUserPage {
  margin-bottom: 2px;
}

@media screen and (max-width: 48rem) {
  .marginBottomUserPage {
    margin-bottom: 2px;
  }
}
