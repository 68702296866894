.newtermTop {
  margin-bottom: 37px;
  border: none;
  box-shadow: var(--ShadowDark);
  padding: 10px;
  /* background-color: white !important; */
  background-color: transparent;
  padding-left: 15px;
}

.paddingLeft {
  padding-left: 15px;
}

.hero {
  background: #286385;
  position: static;
  margin-top: -28px;
  padding-top: 2px;
  min-height: 635px;
  padding: 120px 0;
}

.hero_content h3 {
  color: var(--White, #fff);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.hero_content h4 {
  color: var(--Yellow, #ffc933);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 91.667% */
  margin-top: 28px;
  margin-bottom: 70px;
}

.hero_content h5 {
  color: var(--White, #fff);
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 73.333% */
  margin-bottom: 20px;
}

.btn {
  max-width: max-content;
}
.home_content {
  padding-top: 115px;
  padding-bottom: 125px;
}
.home_content h3 {
  color: var(--Dark-grey, #353535);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home_content h1 {
  color: var(--Navy, #286385);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 38px;
}

.home_content h4 {
  color: var(--Navy, #286385);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 110% */
  margin-bottom: 0;
}

.home_terms {
  padding-top: 115px;
  padding-bottom: 125px;
}

.about_content {
  padding-top: 109px;
  padding-bottom: 127px;
}

.home_terms h1,
.about_content h1 {
  color: var(--Navy, #286385);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px; /* 150% */
  margin-bottom: 20px;
}

.home_terms ul > li {
  list-style: none;
  color: var(--Dark-grey, #353535);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about_header {
  background-image: url("/public/images/background_about.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  margin-top: -28px;
}

.about_content p {
  color: var(--Dark-grey, #353535);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.noMatchBackground {
  background-image: url("/public/images/background_noMatch_blue.svg");
  margin-top: -30px;
  padding-top: 70px;
  padding-bottom: 110px;
}
.noMatchTopMargin {
  margin-top: 45px;
}
.noMatchText {
  color: white;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 768px) {
  .display_none {
    display: none;
  }
  .justifyContentCenter {
    justify-content: center;
  }

  .hero {
    padding: 60px 0;
  }
  .hero_content h3 {
    font-size: 20px;
    line-height: 40px;
    text-align: center;
  }
  .hero_content h4 {
    font-size: 18px;
    line-height: 22px;
    margin-top: 9px;
    margin-bottom: 50px;
    text-align: center;
  }
  .hero_content h5 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px;
    text-align: center;
  }

  .hero_content img {
    max-width: 70% !important;
  }

  .home_content {
    padding-top: 60px;
    padding-bottom: 76px;
    padding-left: 18px;
    padding-right: 18px;
  }
  .home_content h3 {
    font-size: 15px;
    line-height: normal;
  }
  .home_content h1 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .home_terms,
  .about_content {
    padding-top: 58px;
    padding-bottom: 74px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .home_terms h1,
  .about_content h1 {
    font-size: 16px;
    line-height: normal;
  }
  .home_terms ul > li {
    font-size: 15px;
  }

  .about_header {
    height: 384px;
  }
  .about_header img {
    max-width: 60%;
    margin: auto;
  }
  .about_content p {
    font-size: 15px;
    line-height: normal;
    font-weight: 400;
  }
  .noMatchBackground {
    height: 500px;
    margin-top: -20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .noMatchBackground img {
    max-width: 360px;
  }
  .noMatchText {
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
