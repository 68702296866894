@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family:
    "Nunito",
    Avenir,
    "PT Sans",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--BExtraLightGray) !important;
}

.error {
  display: inline-block;
  color: red;
  font-weight: 700;
  padding: 0 1.2rem;
}

.marginBottom {
  margin-bottom: 15px;
}

/*
.headerWithOrderBy {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #999;
}
*/

.quill-inline .ql-toolbar.ql-snow {
  border: transparent !important;
  border-bottom: 1px solid var(--BExtraLightGray) !important;
}
.quill-inline .ql-container.ql-snow {
  border: none !important;
  outline: none !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.form-control-border {
  /* background-color: aquamarine; */
}

.form-control-border:hover fieldset {
  border: 1px solid var(--BBlue) !important;
}

.max-width {
  margin: 0 auto !important;
  max-width: 1220px;
  padding: 0px 9.6px;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.ql-container {
  height: 84% !important;
}

@media (max-width: 768px) {
  .ql-container {
    height: 70% !important;
  }
  .row-sm {
    padding: 0px 16.8px !important;
  }
  .max-width {
    padding: 0px 4.8px;
  }
}
