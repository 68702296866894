.previousNext {
  display: flex;
  justify-content: end;
  gap: 13px !important;
  margin: 23px 0;
}

.previous {
  display: inline-block;
  text-align: left;
}

@media only screen and (min-width: 992px) {
  .previous:hover svg,
  .next:hover svg {
    background-color: var(--BBlueLight);
  }
}

.previous svg,
.next svg {
  padding: 4px;
  background-color: var(--BWhite);
  border-radius: 50%;
  border: 2px solid var(--BBlue);
}

.next {
  text-align: right;
  display: inline-block;
}
