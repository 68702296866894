.newterm {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.newtermTop {
  margin-bottom: 37px;
  border: none;
  box-shadow: var(--ShadowDark);
  padding: 10px;
}

.quillContainer {
  /* margin-bottom: -15px; */
  box-shadow: var(--ShadowDark);
  background-color: var(--BWhite);
  border-radius: 6px;
}

.quillMarginBottom input:focus {
}
