.inlineDefinitionBottomMargin {
  /* margin-bottom: 20px; */
  padding-left: 15px;
}

.quillMarginBottom {
  margin-bottom: 35px;
}

.def_card {
  padding: 24px 20px;
  /* padding-bottom: 20px; */
  background-color: var(--BWhite);
  border-radius: 6px;
  box-shadow: var(--ShadowDark);
  margin-bottom: 12px;
}
.def_card_inline {
  padding: 0px 20px;
  padding-top: 10px;
  background-color: var(--BWhite);
  border-radius: 0px !important;
  box-shadow: none !important;
}

.def_card_inline .inlineDefinitionBottomMargin {
  margin-bottom: 0px;
}

@media screen and (max-width: 48rem) {
  .def_card {
    margin-bottom: 12px;
  }
}
