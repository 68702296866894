.displayByIdTextField {
  margin-bottom: 20px;
}

.displayByIdTextField fieldset {
  border: none !important;
}

.idPage {
  padding: 0px 0px;
  background-color: var(--BWhite);
  /* min-height: 100%; */
  box-shadow: var(--ShadowDark);
  border-radius: 6px;
  margin-top: 11px !important;
}
