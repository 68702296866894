/**
 * https://codepen.io/nzbin/pen/GGrXbp
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dotFlashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 1.5s;
}
.dotFlashing::before,
.dotFlashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dotFlashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}
.dotFlashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  /*background-color: var(--BBlueHover);*/
  color: var(--BBlue);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 2s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--BBlueHover);
  }
  50%,
  100% {
    background-color: var(--BBlueLight);
  }
}

.dotFlashingSmall {
  width: 5px;
  height: 5px;
  animation-delay: 0.5s;
  background-color: var(--BBlueLight);
  color: var(--BBlueHover);
}

.dotFlashingSmall::before {
  left: -8px;
  width: 5px;
  height: 5px;
  animation-delay: 0s;
  background-color: var(--BBlueLight);
  color: var(--BBlueHover);
}
.dotFlashingSmall::after {
  left: 8px;
  width: 5px;
  height: 5px;
  animation-delay: 1s;
  background-color: var(--BBlueLight);
  color: var(--BBlueHover);
}
