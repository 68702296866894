.buttonTopMargin {
  margin-top: 15px;
}

.personalDataPage {
  padding: 20px 19px;
  background-color: var(--BWhite);
  min-height: 100%;
  box-shadow: var(--ShadowDark);
  border-radius: 6px;
}

@media screen and (max-width: 48rem) {
  .personalDataPage {
    padding: 20px 20px;
    min-height: 65vh;
  }
  .buttonTopMargin {
    margin-top: 15px;
    max-width: max-content;
  }
}
