.footer {
  position: fixed;
  padding-top: 3px;
  bottom: 0;
  height: 60px;
  background-color: var(--BExtraLightGray);
  border-top: 2px solid var(--BLightGray);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  z-index: 100;
}

.title {
  margin-top: 2px;
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--BMediumGray);
}

.adminTitle {
  color: var(--BRed);
}

.logo {
  opacity: 0.65;
}
.active .logo {
  opacity: 1;
}

.active .title {
  color: var(--BBlue);
  font-weight: 600;
}

.active .adminTitle {
  color: var(--BRed);
  font-weight: 600;
}
