.link-simple {
  color: var(--BDarkGray);
  /* white-space: nowrap; */
  text-align: center;
  font-size: 0.875rem;
  text-decoration: none;
  -webkit-border-radius: 50px;
  /* Safari, Chrome */
  -moz-border-radius: 50px;
  /* Firefox */
  border-radius: 30px;
  display: block;
  /* min-height: 46px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 2px; */
  font-size: 15px !important;
  color: var(--BDarkGray);
  font-size: 500;
  padding: 0.5rem 1rem;
}

.link-simple-right {
  -webkit-border-radius: 8px;
  /* Safari, Chrome */
  -moz-border-radius: 8px;
  /* Firefox */
  border-radius: 8px;
}

.link-profile {
  font-size: 16px;
  color: var(--BDarkGray);
  font-weight: 600;
  padding: 12px 0px;
}

.link-profile:hover {
  color: var(--BNavy);
}

.active {
  color: var(--BNavy) !important;
  border-right: 4px solid var(--BNavy);
}

.link-simple:hover {
  background-color: var(--BBlueLight);
  color: var(--BNavy);
  transition: all 150ms ease-out;
}

.link-solid,
.link-solid-yellow,
.link-border {
  border: none;
  min-width: 90px;
}

.link-solid {
  background-color: var(--BNavy);
  border-color: var(--BNavy);
  color: white;
  font-weight: bold;
}
.link-solid:hover {
  background-color: var(--BNavyHover);
  transition: all 150ms ease-out;
  color: white;
}

.link-solid-yellow {
  background-color: var(--BYellow);
  border-color: var(--BYellow);
  color: var(--BNavy);
  font-weight: bold;
}

.link-solid-yellow:hover {
  background-color: var(--BYellowHover);
  transition: all 150ms ease-out;
  color: var(--BNavy);
}

.link-border {
  background-color: white;
  color: var(--BBlue);
  border: 2px solid var(--BBlue);
  border-color: var(--BBlue);
  font-weight: bold;
}

.link-border:hover {
  background: var(--BBlueLight);
  transition: all 150ms ease-out;
  color: var(--BNavy);
}

.link-title {
  color: var(--BBlue);
  background: white;
  /*white-space: nowrap;*/
  text-align: center;
  text-decoration: none;
  font-weight: 700;
}

.link-title:hover {
  color: var(--BNavyHover);
  background: white;
  transition: all 150ms ease-out;
}
.link-big-title {
  text-decoration: none;
  opacity: 1;
}

.link-big-title:hover {
  color: var(--BNavyHover);
  background: white;
  transition: opacity 150ms ease-out;
  opacity: 0.8;
}
.link-underline-blue {
  text-decoration: none;
  background-color: transparent;
  color: var(--BBlue);
  align-self: center;
  outline: none;
  border: none;
  border-bottom: 3px solid;
  border-color: transparent;
  padding-bottom: 5px;
  font-size: 0.875rem;
  font-weight: bold;
}

.link-underline-blue:hover {
  color: var(--BNavy);
  border-color: var(--BNavy);
  transition: all 150ms ease-out;
  border-bottom: 3px solid;
}

@media (max-width: 48em) {
  .link-border {
    padding: 4px 10px;
  }
  .link-simple-right {
    background-color: var(--BExtraLightGray);
    border-bottom: 1px solid var(--BExtraLightGray);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: 0px 5px;
  }
  .link-solid,
  .link-solid-yellow {
    padding: 4px 10px;
  }
  /* .active {
    background-color: var(--BBlueLight);
    border-bottom: 1px solid var(--BBlue);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  } */

  .link-simple {
    display: block;
    min-width: 75px;
    min-height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .link-underline-blue {
    border: none;
  }

  .link-underline-blue:hover {
    border: none;
  }

  .link-profile {
    font-size: 18px;
    padding: 16px 12px;
  }
}
