/* Mobile  */

.btnMobileNav {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #333;
  display: none;
}

.iconMobileNav {
  width: 32px;
  height: 32px;
}

.iconMobileNav[name="close"] {
  display: none;
}

.account {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accountMobile {
  display: none;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1220px;
  padding-right: 9.6px;
  min-height: 64px;
  margin: auto;
  gap: 18px;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainHeader {
  background-color: var(--BWhite);
  box-shadow: var(--Shadow);
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky !important;
  top: 0;
  z-index: 1000;
}

.searchBox {
  /* margin-top: 4px; */
  flex: 1;
  width: 1fr;
}

.logo {
  padding: 0.3rem 0.6rem;
  height: 33px;
  margin: 0;
  -webkit-border-radius: 5px;
  /* Safari, Chrome */
  -moz-border-radius: 5px;
  /* Firefox */
  border-radius: 5px;
}
.logo:hover {
  /* background-color: var(--BBlueLight); */
  transition: all 150ms ease-out;
}

.horizontalNav {
  display: flex;
  gap: 14px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-right: 0px;
}
.horizontalNavLink {
  display: flex;
  gap: 17px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-right: 13px;
  margin-left: 7px;
}
.profilePadding {
  padding: 0px 9px;
}

.profile {
  height: 56px;
  color: 2px solid var(--BBlue);
  border-bottom: 2px solid var(--BBlue);
  margin-bottom: -2px;
}

@media screen and (max-width: 1000px) {
  .horizontalNavLink {
    display: flex;
    gap: 20px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-right: 21px;
  }
}

@media (max-width: 48em) {
  .mainHeader {
    padding-left: 2.6px !important;
  }
  .lockedHeader {
    padding-left: 2.6px !important;
    padding-right: 6.6px !important;
  }
  .accountMobile {
    display: flex;
  }
  .btnMobileNav {
    justify-self: end;
    display: block;
  }

  .logo {
    padding: 0.1rem 0.6rem;
    height: 24px !important;
    -webkit-border-radius: 2px;
    /* Safari, Chrome */
    -moz-border-radius: 2px;
    /* Firefox */
    border-radius: 2px;
    object-fit: contain;
  }
  .logo:hover:hover {
    background-color: transparent;
  }
  .container {
    width: 100%;
    gap: 0px;
    padding-right: 9.8px;
    padding-left: 5px;
  }
  .navdiv {
    background-color: rgb(255, 255, 255, 0.97);
    position: absolute;
    width: 100%;
    height: 50vh;
    top: 60px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: all 0.5s;
    z-index: 1000;
  }

  .verticalNavOnMobile {
    flex-direction: column;
    text-align: center;
  }

  .link-simple {
    font-size: 1.125rem;
    font-weight: 700;
  }
  .navopen .iconMobileNav[name="menu"] {
    display: none;
  }
  .navopen .iconMobileNav[name="close"] {
    display: block;
  }

  .navopen .navdiv {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: all 0.5s;
  }
  .searchBox {
    display: none;
  }
  .horizontalNav {
    gap: 9px;
    margin-right: 0px;
  }
  .mobile_icon_default {
    padding: 0px 9px;
  }
}
