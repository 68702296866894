:root {
  --BNavy: #286385;
  --BNavyLight: #d4e0e7;
  --BNavyHover: #53829d;
  /**/
  --BBlue: #3788b8;
  --BBlueLight: #d7e7f1;
  --BBlueHover: #5fa0c6;
  /**/
  --BYellow: #ffc933;
  --BYellowHover: #ffd45c;
  /**/
  --BRed: #db4a21;
  --BRedHover: #e26e4d;
  /**/
  --BDarkGray: #353535;
  --BMediumGray: #969696;
  --BLightGray: #e6e6e6;
  --BExtraLightGray: #fafafa;
  --BWhite: #ffffff;
  /* */
  --Shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
  --ShadowDark: 0px 4px 11px 0px rgba(0, 0, 0, 0.1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none !important;
  color: black;
}

.cursor-pointer {
  cursor: pointer;
}

input:focus {
  font-size: 1rem !important;
}

@media screen and (max-width: 48rem) {
  .modal-dialog {
    min-width: 100vw !important;
  }
  .modal.fade .modal-dialog {
    transform: translate3d(0, 10vh, 0) !important;
  }

  .modal.show .modal-dialog {
    transform: translate3d(0, 0, 0) !important;
  }
}
