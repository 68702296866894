.definition {
  display: flex;
  gap: 15px;
  margin-bottom: 12px;
  box-shadow: var(--ShadowDark);
  background-color: var(--BWhite);
  border-radius: 6px;
  padding: 24px 29px;
}

.inline_def {
  box-shadow: none !important;
  border-radius: 0px !important;
  /* background-color: aqua; */
  border-top: 1px solid var(--BLightGray) !important;
  padding-bottom: 0px;
}

.definitionContent {
  flex: 1;
  font-weight: 300;
  color: var(--BDarkGray);
}

.overflowWrap {
  overflow-wrap: anywhere;
  white-space: normal;
}

.editDeleteButtons {
  justify-self: start;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.quillContainer {
  /* margin-bottom: -15px; */
  box-shadow: var(--ShadowDark);
  background-color: var(--BWhite);
  border-radius: 6px;
}

.newtermTop {
  margin-bottom: 37px;
  border: none;
  box-shadow: var(--ShadowDark);
  padding: 10px;
  /* background-color: white !important; */
  background-color: transparent;
  padding-left: 15px;
}

.definitionMeta {
  font-size: 14px;
  display: flex;
  gap: 30px;
  list-style-type: none;
  padding: 0;
  color: var(--BMediumGray);
  margin-bottom: 0px;
  font-weight: 500 !important;
}

.definitionAuthor {
  color: var(--BNavy);
  font-weight: 700 !important;
}
.definitionAuthorAdmin {
  color: var(--BRed);
}

.quillMarginBottom {
  margin-bottom: 45px;
}

.avatar {
  min-height: 40px;
  min-width: 40px;
  margin-top: auto;
  margin-bottom: auto;
  background: var(--BNavy);
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: var(--BWhite);
  font-weight: bolder;
}

.avatar:hover {
  background: var(--BNavyHover);
}

@media screen and (max-width: 48rem) {
  .definitionMeta {
    flex-direction: column;
    gap: 2px;
    font-size: 0.6rem;
  }
  .definition {
    padding: 20px 16px;
  }
}
