.modal {
  display: flex;
  justify-content: space-between;
}

.modalBody {
  background: var(--BExtraLightGray);
  border-radius: 6px;
  padding: 16px 30px;
}

@media screen and (max-width: 48rem) {
  .modalBody {
    padding: 14px 16px;
  }
}
