.approveReject {
  justify-self: start;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.newtermTop {
  margin-bottom: 37px;
  border: none;
  box-shadow: var(--ShadowDark);
  padding: 10px;
  background-color: white !important;
  padding-left: 15px;
}

.marginBottom {
  margin-bottom: 37px;
}
