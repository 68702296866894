.smallTitle {
  color: var(--BDarkGray);
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 14px;
}

.topMargin {
  margin-top: 25px;
}
.topMargin2 {
  margin-top: 35px;
}

.pageHr {
  margin-top: 30px;
  margin-bottom: 30px;
  color: var(--BDarkGray);
}
